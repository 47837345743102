const Web3 = require('web3'),
    bnFormat = require('./bn-format'),
    REGEXP_REPLACE_LEADING_ZEROS = /^0*/;

const numberFormat = (locales, value, decimals, onlySignificant, withSuffix, minDigits) => {
    let valueString, valueBN, left, right, valueStringLength, intValue, givenDecimals;
    const valueToString = value.toString();

    if (valueToString.indexOf('e') !== -1) {
        console.error('Error numberFormat: exponent values are not supported', value);
        return '';
    }
    intValue = Math.floor(value);
    givenDecimals = valueToString.length - intValue.toString().length;

    if (givenDecimals === 0) {
        valueString = valueToString+ ''.padEnd(18, '0');
    }
    else {
        givenDecimals--; // actually the nr of decimals is one less, because the decimal divider took 1 character
        // never handle more than 18 decimals:
        if (givenDecimals > 18) {
            valueString = valueToString;
            valueStringLength = valueString.length;
            valueString = valueString.substr(0, valueStringLength - givenDecimals + 18);
        }
        else {
            valueString = valueToString + ''.padEnd(Math.max(0, 18 - givenDecimals), '0');
        }
        // remove the decimal divider:
        valueStringLength = valueString.length;
        left = valueString.substr(0, valueStringLength - 19);
        right = valueString.substr(valueString.length - 18);
        valueString = left + right;
        valueString = valueString.replace(REGEXP_REPLACE_LEADING_ZEROS, '');
    }
    try {
        valueBN = Web3.utils.toBN(valueString);
    }
    catch (err) {
        console.error('Error numberFormat: cannot convert', valueString, 'to BigNumber', value);
        return '';
    }
    // return the formatted version:
    return bnFormat(locales, valueBN, decimals, onlySignificant, withSuffix, minDigits);
};

module.exports = numberFormat;
