import React, {useEffect, useState} from 'react';
import {Snackbar, SnackbarAction} from '@rmwc/snackbar';

export default function Title({title, subTitle, isOld, snackbarMessage}) {
    const [shown, setShown] = useState(false);
    const [open, setOpen] = useState(isOld);
    const loaderClass = (!isOld) ? 'done' : 'fetching';
    let snackBar1;
    let snackBar2;

    useEffect(() => {
        setOpen(true);
        if (isOld) {
            setShown(true);
        }
    }, [isOld]);

    if (shown) {
        if (isOld) {
            snackBar1 = (
                <Snackbar
                    action={<SnackbarAction icon="close" />}
                    className={loaderClass}
                    dismissesOnAction
                    leading
                    message={snackbarMessage}
                    onClose={() => setOpen(false)}
                    open={open}
                    stacked
                    timeout={600000}/>
            );
        }
        else {
            snackBar2 = (
                <Snackbar
                    action={<SnackbarAction icon="close" />}
                    className={loaderClass}
                    dismissesOnAction
                    leading
                    message={snackbarMessage}
                    onClose={() => setOpen(false)}
                    open={open}
                    stacked
                    timeout={4000}/>
            );
        }
    }

    return (
        <section className="title">
            {snackBar1}
            {snackBar2}
            <div className="title-text">
                <h1 dangerouslySetInnerHTML={{__html: title}} />
                <span dangerouslySetInnerHTML={{__html: subTitle}} />
            </div>
        </section>
    );
}
