import React from 'react';
import Title from '../../Title';
import Footer from '../../Footer';
import DifficultyChart from '../../charts/difficulty-chart';
// import StakedAmountChart from '../../charts/staked-amount-chart';

import translations from '../../../lib/translations';

const StakingAnalytics = props => {
    const translate = translations.init(props);
    // as soon as anount of staked can be retireved from exteernal service Energi Metrics,
    // we will reanable the staking chart as second chart next to the difficulty chart
    // <StakedAmountChart {...props} />
    return (
        <div className="masternodes pure-u subpage">
            <Title subTitle="" title={translate.t('STAKING_ANALYTICS')} />
            <section className="analytics">
                <DifficultyChart {...props} />
            </section>
            <Footer {...props} />
        </div>
    );
};

export default StakingAnalytics;
